import { useState, useEffect } from "react";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import { jwtDecode } from 'jwt-decode';
import { Space, Spin } from "antd";

import AppLayout from "./../layouts/AppLayout";

import NotFound from "src/modules/NotFound";
import LoginPage from "src/modules/auth/pages/Login";
import RecoveryPage from "src/modules/auth/pages/Recovery";

import Dashboard from "src/modules/dashboard";

import DeviceList from "src/modules/devices/pages/List";
import UserPermissions from "src/modules/users/pages/Permissions";
import UserList from "src/modules/users/pages/ListUsers";
import UserNew from "src/modules/users/pages/NewUser";
import UserEdit from "src/modules/users/pages/EditUser";
import UserProfile from "src/modules/users/pages/Profile";
import UserDetail from "src/modules/users/pages/DetailUser";

import AllyList from "src/modules/allies/pages/ListAllies";
import AllyNew from "src/modules/allies/pages/NewAlly";
import AllyEdit from "src/modules/allies/pages/EditAlly";

import { useDispatch, useSelector } from "react-redux";

import SettingNew from "src/modules/settings/pages/EditSetting";
import Files from "src/modules/multimedia/pages/Files";

import PackageNew from "src/modules/campaign/pages/NewCampaign";
import PackageList from "src/modules/campaign/pages/ListCampaigns";
import PackageEdit from "src/modules/campaign/pages/EditCampaign";

import ZoneNew from "src/modules/zones/pages/NewZone";
import ZoneList from "src/modules/zones/pages/ListZones";
import ZoneEdit from "src/modules/zones/pages/EditZone";

import NofiticationsList from "src/modules/notifications/pages/ListNotifications";
import ActiveUser from "src/modules/auth/pages/Verification";
import UpdatePassword from "src/modules/auth/pages/UpdatePassword";
import MetricsPage from "src/modules/metrics/pages/Metrics";
import AdvertisersPage from "src/modules/advertisers/pages/ListAdvertisers";
import CreateAdvertisers from "src/modules/advertisers/pages/NewAdvertisers";
import EditAdvertisers from "src/modules/advertisers/pages/EditAdvertisers";
import OneCampaign from "src/modules/campaign/pages/OneCampaign";
import Map from "src/modules/map/pages/Map";
import MapLayout from "src/layouts/MapLayout";
import { cleanAll } from "src/redux/slices/auth.slice";
import InactivityHandler from "src/hooks/useInactivity";

const RoutesPages = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { auth } = useSelector((state: any) => state);

  const AppRoutes = () => {
    return (
      <Router>
        <InactivityHandler/>
        <Routes>
          <Route path={"*"} element={<NotFound />} />
          <Route path={"/"} element={<Navigate replace to={"/dashboard"} />} />
          <Route
            path={"/auth/*"}
            element={<Navigate replace to={"/dashboard"} />}
          />

          <Route
            path={"/dashboard"}
            element={
              <AppLayout title="Inicio">
                <Dashboard />
              </AppLayout>
            }
          />

          <Route
            path={"/metrics"}
            element={
              <AppLayout title="Inicio">
                <MetricsPage />
              </AppLayout>
            }
          />

          <Route
            path={"/devices"}
            element={
              <AppLayout title="Dispositivos">
                <DeviceList />
              </AppLayout>
            }
          />
          <Route
            path={"/map"}
            element={
              <MapLayout title="Mapa">
                <Map />
              </MapLayout>
            }
          />

          <Route
            path={"/users"}
            element={
              <AppLayout title="Gestión de usuarios">
                <UserList />
              </AppLayout>
            }
          />
          <Route
            path={"/users/new"}
            element={
              <AppLayout title="Nuevo usuario">
                <UserNew />
              </AppLayout>
            }
          />
          <Route
            path={"/users/:id/edit"}
            element={
              <AppLayout title="Editar usuario">
                <UserEdit />
              </AppLayout>
            }
          />
          <Route
            path={"/users/:id/permissions"}
            element={
              <AppLayout title="Permisos del usuario">
                <UserPermissions />
              </AppLayout>
            }
          />
          <Route
            path={"/profile"}
            element={
              <AppLayout title="Perfil">
                <UserProfile />
              </AppLayout>
            }
          />
          <Route
            path={"/users/:id"}
            element={
              <AppLayout title="Detalle de usuario">
                <UserDetail />
              </AppLayout>
            }
          />

          <Route
            path={"/riders"}
            element={
              <AppLayout title="Gestión de Riders">
                <AllyList />
              </AppLayout>
            }
          />
          <Route
            path={"/riders/:id/devices"}
            element={
              <AppLayout title="Dispositivos de rider">
                <DeviceList />
              </AppLayout>
            }
          />
          <Route
            path={"/riders/new"}
            element={
              <AppLayout title="Nuevo rider">
                <AllyNew />
              </AppLayout>
            }
          />
          <Route
            path={"/riders/:id/edit"}
            element={
              <AppLayout title="Editar rider">
                <AllyEdit />
              </AppLayout>
            }
          />

          <Route
            path={"/advertisers"}
            element={
              <AppLayout title="Gestión de Anunciantes">
                <AdvertisersPage />
              </AppLayout>
            }
          />
          {/*  <Route path={'/advertisers/:id/devices'} element={<AppLayout title="Dispositivos de rider"><DeviceList /></AppLayout>} /> */}
          <Route
            path={"/advertisers/new"}
            element={
              <AppLayout title="Nuevo anunciante">
                <CreateAdvertisers />
              </AppLayout>
            }
          />
          <Route
            path={"/advertisers/:id/edit"}
            element={
              <AppLayout title="Editar anunciante">
                <EditAdvertisers />
              </AppLayout>
            }
          />

          <Route
            path={"/Files"}
            element={
              <AppLayout title="Archivos">
                <Files />
              </AppLayout>
            }
          />

          <Route
            path={"/campaign"}
            element={
              <AppLayout title="Gestión de campañas">
                <PackageList />
              </AppLayout>
            }
          />
          <Route
            path={"/campaign/new"}
            element={
              <AppLayout title="Nueva campaña">
                <PackageNew />
              </AppLayout>
            }
          />
          <Route
            path={"/campaign/:id/edit"}
            element={
              <AppLayout title="Editar campaña">
                <PackageEdit />
              </AppLayout>
            }
          />
          <Route
            path={"/campaign/:id/one"}
            element={
              <AppLayout title="Ver campaña">
                <OneCampaign />
              </AppLayout>
            }
          />

          <Route
            path={"/zones"}
            element={
              <AppLayout title="Gestión de zonas">
                <ZoneList />
              </AppLayout>
            }
          />
          <Route
            path={"/zones/new"}
            element={
              <AppLayout title="Nueva zona">
                <ZoneNew />
              </AppLayout>
            }
          />
          <Route
            path={"/zones/:id/edit"}
            element={
              <AppLayout title="Editar zona">
                <ZoneEdit />
              </AppLayout>
            }
          />

          <Route
            path={"/notifications"}
            element={
              <AppLayout title="Notificaciones">
                <NofiticationsList />
              </AppLayout>
            }
          />

          <Route
            path={"/settings"}
            element={
              <AppLayout title="Gestión de configuración">
                <SettingNew />
              </AppLayout>
            }
          />
        </Routes>
      </Router>
    );
  };

  const AuthRoutes = () => {
    return (
      <Router>
        <Routes>
          <Route path={"*"} element={<Navigate replace to={"/auth/login"} />} />
          <Route path={"/auth"}>
            <Route index element={<Navigate to={"/auth/login"} />} />
            <Route path={"login"} element={<LoginPage />} />
            <Route path={"forgot"} element={<RecoveryPage />} />
            <Route path={"*"} element={<Navigate to={"/auth/login"} />} />
            <Route path={`/auth/confirm/:token`} element={<ActiveUser />} />
            <Route path={"/auth/recover/:token"} element={<UpdatePassword />} />
          </Route>
        </Routes>
      </Router>
    );
  };

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (auth.token) {
        const decodedToken = jwtDecode(auth.token);
        if (decodedToken && decodedToken.exp) {
          const expirationTime = decodedToken.exp * 1000;
          if (Date.now() >= expirationTime) {
            dispatch(cleanAll());
          }
        } else {
          console.error('El token no tiene una fecha de expiración.');
        }
      }
    };
    checkTokenExpiration();
    // Intervalo para verificar el token cada minuto
    const intervalId = setInterval(checkTokenExpiration, 60000);
    return () => clearInterval(intervalId);
  }, [auth, dispatch, history]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [auth]);

  if (loading) {
    return (
      <div
        style={{
          display: !loading ? "none" : "flex",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
        data-testid="loading-app"
      >
        <Space size="middle">
          <Spin size="large" />
        </Space>
      </div>
    );
  }

  if (auth.isLogged) {
    return <AppRoutes />;
  } else {
    return <AuthRoutes />;
  }
};

export default RoutesPages;
