import { useState, useEffect } from "react";
import {
  CheckSquareOutlined,
  DeleteOutlined,
  EditOutlined,
  EuroCircleOutlined,
  ExclamationCircleOutlined,
  EyeFilled,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  message,
  Popconfirm,
  Table,
  Tooltip,
  Checkbox,
  Modal,
  Card,
  Divider,
} from "antd";
import { ColumnsType } from "antd/es/table";
import SpaceStyled from "src/components/styled/Space";
import { IPermission, IPackage } from "src/core/dtos";
import { ITableProps } from "src/core/interfaces";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FC } from "react";
import usePageLimit from "src/hooks/usePageLimit";
import {
  deletePackage,
  getPackages,
  getPackagesAdvertiserId,
  updatePackageStatus,
} from "src/services/campaign";
import PackageStatus from "./PackageStatus";
import moment from "moment";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import ModalPassesByZone from "./ModalPassesByZone";

const PackageTable: FC<ITableProps> = ({ search, selectedZone, statusP }) => {
  const { permissions } = useSelector((state: any) => state.auth);
  const { user } = useSelector((state: any) => state.auth);
  const permCamp = permissions.find(
    (item: IPermission) => item.module === "Packages"
  );
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const { page, limit, handleLimitChange, handlePageChange } = usePageLimit();
  const [campaigns, setPackages] = useState<IPackage[]>([]);
  const [total, setTotal] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [packageDet, setPackageDet] = useState<IPackage | undefined>();

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const loadPackages = async () => {
    setIsLoading(true);

    let response;
    if (user.role === "ADVERTISER") {
      response = getPackagesAdvertiserId(+user.advertiserId, [
        { key: "page", value: page },
        { key: "limit", value: limit },
        { key: "search", value: String(search) },
        { key: "zone", value: Number(selectedZone) },
        { key: "status", value: String(statusP) },
      ]);
    } else {
      response = getPackages([
        { key: "page", value: page },
        { key: "limit", value: limit },
        { key: "search", value: String(search) },
        { key: "zone", value: Number(selectedZone) },
        { key: "status", value: String(statusP) },
      ]);
    }

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    setPackages(data?.data);
    setTotal(data?.total);
    setIsLoading(false);
  };

  type IStatusType = "APPROVED" | "REJECTED" | "PENDING" | "ACTIVE" | "ACTIVE";

  const updateStatusCampain = async (
    campain: IPackage,
    status: IStatusType
  ) => {
    setIsLoading(true);
    const {
      data: { message, statusCode },
    } = await updatePackageStatus(campain.id as number, {
      status,
    });
    if (statusCode !== 200) {
      messageApi.error(
        message || "Error al actualizar el estado de la campaña"
      );
      setIsLoading(false);
      return;
    }
    messageApi.success(message || "Estado actualizado exitosamente");
    loadPackages();
    setIsLoading(false);
  };

  useEffect(() => {
    loadPackages();
  }, [page, limit, search, selectedZone, statusP]);

  const campaignsTableColumns: ColumnsType<IPackage> = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      render: (_: any, campaigns) => (
        <span style={{ cursor: "pointer" }}>{campaigns.name}</span>
      ),
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      render: (_: any, campaigns) => (
        <span style={{ cursor: "pointer" }}>{campaigns.description}</span>
      ),
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      render: (_: any, item) => (
        <PackageStatus
          initialStatus={item.status ? item.status.toString() : ""}
        />
      ),
    },
    {
      title: "Presupuesto",
      dataIndex: "budget",
      key: "budget",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      render: (_: any, campaigns) => (
        <span style={{ cursor: "pointer" }}>
          <EuroCircleOutlined /> {campaigns.budget}
        </span>
      ),
    },
    {
      title: "Fecha de inicio",
      dataIndex: "startDate",
      key: "startDate",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      render: (_: any, campaigns) => (
        <span style={{ cursor: "pointer" }}>
          {moment(campaigns.startDate).format("YYYY-MM-DD")}
        </span>
      ),
    },
    {
      title: "Fecha de fin",
      dataIndex: "endDate",
      key: "endDate",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      render: (_: any, campaigns) => (
        <span style={{ cursor: "pointer" }}>
          {moment(campaigns.endDate).format("YYYY-MM-DD")}
        </span>
      ),
    },
    {
      title: (
        <>
          <Tooltip placement="top" title="Cantidad de Pases Reproducidos">
            {"Reproduciones"}
          </Tooltip>
        </>
      ),
      dataIndex: "passes",
      key: "passes",
      render: (_: any, campaigns) => {
        let totalPasses = 0;
        if (campaigns.passes) {
          totalPasses = campaigns.passes.reduce(
            (total, pass) => total + pass.count,
            0
          );
        }
        return <span>{totalPasses}</span>;
      },
    },
    {
      title: (
        <>
          <Tooltip
            placement="top"
            title="Cantidad de Pases Reproducidos por Zonas"
          >
            {"Pases por zonas"}
          </Tooltip>
        </>
      ),
      dataIndex: "passesByZones",
      key: "passesByZones",
      width: "30px",
      align: "center",
      render: (_: any, campaigns) => (
        <SpaceStyled justify="center" block={true}>
          <Tooltip title="Ver listado de pases">
            <Button
              shape="circle"
              icon={<EyeFilled />}
              type="default"
              size="middle"
              onClick={() => {
                setPackageDet(campaigns);
                setIsOpen(!isOpen);
              }}
            />
          </Tooltip>
        </SpaceStyled>
      ),
    },
    {
      title: "Opciones",
      dataIndex: "actions",
      key: "actions",
      width: "20px",
      align: "center",
      render: (_: any, campaigns) => (
        <SpaceStyled justify="center" block={true}>
          {permCamp.isEdit && (
            <>
              <Tooltip title="Editar campaña">
                <Link to={`/campaign/${campaigns.id}/edit`}>
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    type="primary"
                    size="middle"
                  />
                </Link>
              </Tooltip>
            </>
          )}

          <Tooltip title="Ver campaña">
            <Link to={`/campaign/${campaigns.id}/one`}>
              <Button
                shape="circle"
                icon={<EyeFilled />}
                type="primary"
                size="middle"
              />
            </Link>
          </Tooltip>

          {user.role === "ADMIN" && permCamp.isEdit && (
            <Tooltip title="Aprobar / Rechazar">
              <Popconfirm
                title="Aprobar o Rechazar la campaña"
                okButtonProps={{ danger: true }}
                cancelText="Aprobar campaña ✅"
                okText="Rechazar campaña 🗑️"
                cancelButtonProps={{
                  style: {
                    color: "#08ac6e",
                    borderColor: "#08ac6e",
                  },
                }}
                onCancel={() => updateStatusCampain(campaigns, "APPROVED")}
                onConfirm={() => updateStatusCampain(campaigns, "REJECTED")}
              >
                <Button
                  shape="circle"
                  icon={<ExclamationCircleOutlined />}
                  type="default"
                  size="middle"
                  style={{
                    color: "#faad14",
                    background: "#fffbe6",
                    borderColor: "#ffe58f",
                  }}
                />
              </Popconfirm>
            </Tooltip>
          )}

          {user.role !== "ADVERTISER" && permCamp.isDel && (
            <Tooltip title="Eliminar campaña">
              <Popconfirm
                title="¿Está seguro de eliminar la campaña?"
                okButtonProps={{ danger: true }}
                onConfirm={() => onConfirmDelete(campaigns)}
              >
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  type="primary"
                  danger
                  size="middle"
                />
              </Popconfirm>
            </Tooltip>
          )}
        </SpaceStyled>
      ),
    },
  ];

  const [selectedColumns, setSelectedColumns] = useState([
    "name",
    "description",
    "status",
    "budget",
    "actions",
  ]);

  const filteredColumns = campaignsTableColumns.filter((column) =>
    selectedColumns.includes(String(column.key))
  );

  const handleColumnChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedColumns(checkedValues.map((value) => value.toString()));
  };

  const onConfirmDelete = async (campaigns: IPackage) => {
    const response: any = deletePackage(campaigns.id ?? 0);

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data.message || "Ha ocurrido un error al intentar eliminar el campaña"
      );
      return;
    }

    if (data.statusCode !== 200) {
      messageApi.error(
        data.message || "Ha ocurrido un error al intentar eliminar el campaña"
      );
      return;
    }
    messageApi.success(
      data.message || "Se ha eliminado exitosamente el campaña"
    );
    loadPackages();
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="Seleccione los campos a mostrar"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Card>
          <Checkbox.Group
            style={{
              marginBottom: "20px",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "10px",
            }}
            value={selectedColumns}
            onChange={handleColumnChange}
          >
            {campaignsTableColumns.map((col, index) => (
              <Checkbox
                key={index}
                value={
                  typeof col.key === "bigint"
                    ? col.key.toString()
                    : col.key ?? ""
                }
              >
                {col.title as string}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Card>
      </Modal>
      <Divider />
      <Button
        style={{ marginBottom: "15px", float: "right" }}
        type="primary"
        icon={<CheckSquareOutlined />}
        onClick={showModal}
      >
        Seleccionar campos a mostrar
      </Button>

      <Table
        columns={filteredColumns}
        dataSource={campaigns}
        loading={isLoading}
        rowClassName="bg-white"
        pagination={{
          defaultCurrent: 1,
          current: page,
          pageSize: limit,
          defaultPageSize: 10,
          onChange(page, pageSize) {
            handlePageChange(page);
            handleLimitChange(pageSize);
          },
          showSizeChanger: true,
          total: total,
          pageSizeOptions: ["15", "25", "50", "100"],
        }}
        scroll={{ x: "calc(400px + 50%)" }}
      />
      <ModalPassesByZone
        isOpen={isOpen}
        toggle={setIsOpen}
        packages={
          packageDet?.id
            ? { id: packageDet.id, name: packageDet.name }
            : { id: 0, name: "" }
        }
      />
    </>
  );
};

export default PackageTable;
