import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Typography,
  message,
  DatePicker,
  Card,
  Statistic,
  Tooltip as AntdTooltip,
} from "antd";
import SpaceStyled from "src/components/styled/Space";
import {
  ClockCircleOutlined,
  InfoCircleTwoTone,
  SyncOutlined,
} from "@ant-design/icons";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import CardStatistic from "src/modules/dashboard/components/CardStatistic";
import { activitiesHours, countActivitiesHours } from "src/services/activities";
import CardTools from "src/modules/dashboard/components/CardTools";
import { useSelector } from "react-redux";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
} from "recharts";
import dayjs, { Dayjs } from "dayjs";
import {
  countPasses,
  countPassesCampaigns,
  countPassesZones,
} from "src/services/passes";
import { Activity, DataItem, IAPISourceList } from "src/core/dtos";

const MetricsPage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [activities, setActivities] = useState<Activity[]>([]);
  const [passesCampaigns, setPassesCampaigns] = useState<DataItem[]>([]);
  const [passesZones, setPassesZones] = useState<DataItem[]>([]);
  const [passes, setPasses] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state: any) => state.auth);
  const role = useSelector((state: any) => state.auth.user.role);
  const [start, setStart] = useState<Dayjs | null>(null);
  const [end, setEnd] = useState<Dayjs | null>(null);
  const [totalHours, setTotalHours] = useState(0);

  const getActivities = async (first?: boolean) => {
    let parameters: IAPISourceList<"Activities">[] = [];

    Boolean(start) &&
      start !== null &&
      parameters.push({
        key: "from",
        value: String(start.format("YYYY-MM-DD")),
      });
    Boolean(end) &&
      end !== null &&
      parameters.push({
        key: "to",
        value: String(end.format("YYYY-MM-DD")),
      });

    Boolean(user?.allyId) &&
      parameters.push({ key: "ally", value: String(user?.allyId) });

    const {
      data: { data, statusCode },
    } = await countActivitiesHours(parameters);

    setTotalHours(data.total);
  };

  const getActivitiesHours = async (first?: boolean) => {
    let parameters: IAPISourceList<"Activities">[] = [];

    Boolean(start) &&
      start !== null &&
      parameters.push({
        key: "from",
        value: String(start.format("YYYY-MM-DD")),
      });
    Boolean(end) &&
      end !== null &&
      parameters.push({
        key: "to",
        value: String(end.format("YYYY-MM-DD")),
      });

    Boolean(user?.allyId) &&
      parameters.push({ key: "ally", value: String(user?.allyId) });

    const {
      data: { data, statusCode, total },
    } = await activitiesHours(parameters);

    setActivities(data);
  };

  const getPassesCampaigns = async (first?: boolean) => {
    let parameters: IAPISourceList<"Passes">[] = [];

    Boolean(start) &&
      start !== null &&
      parameters.push({
        key: "from",
        value: String(start.format("YYYY-MM-DD")),
      });
    Boolean(end) &&
      end !== null &&
      parameters.push({
        key: "to",
        value: String(end.format("YYYY-MM-DD")),
      });

    Boolean(user?.advertiserId) &&
      parameters.push({ key: "advertiser", value: String(user?.advertiserId) });

    const {
      data: { data, statusCode, total },
    } = await countPassesCampaigns(parameters);

    setPassesCampaigns(data);
  };

  const getPassesZones = async (first?: boolean) => {
    let parameters: IAPISourceList<"Passes">[] = [];

    Boolean(start) &&
      start !== null &&
      parameters.push({
        key: "from",
        value: String(start.format("YYYY-MM-DD")),
      });
    Boolean(end) &&
      end !== null &&
      parameters.push({
        key: "to",
        value: String(end.format("YYYY-MM-DD")),
      });

    Boolean(user?.advertiserId) &&
      parameters.push({ key: "advertiser", value: String(user?.advertiserId) });

    const {
      data: { data, statusCode, total },
    } = await countPassesZones(parameters);

    setPassesZones(data);
  };

  const getPasses = async (first?: boolean) => {
    let parameters: IAPISourceList<"Passes">[] = [];

    Boolean(start) &&
      start !== null &&
      parameters.push({
        key: "from",
        value: String(start.format("YYYY-MM-DD")),
      });
    Boolean(end) &&
      end !== null &&
      parameters.push({
        key: "to",
        value: String(end.format("YYYY-MM-DD")),
      });

    Boolean(user?.allyId) &&
      parameters.push({ key: "ally", value: String(user?.allyId) });

    const {
      data: { data, statusCode, total },
    } = await countPasses(parameters);

    if (statusCode !== 200) {
      messageApi.error("Ha ocurrido un error al cargar los datos");
      setLoading(false);
      return;
    }

    setPasses(data);
  };

  const HoursQuantity = activities.map((item) => {
    const date = parseISO(item.date);
    const formattedDate = format(date, "dd/MM/yyyy");
    const dayOfWeek = format(date, "eeee", { locale: es });

    return {
      name: `${formattedDate}`,
      Horas: item.hours,
    };
  });

  const passesQuantityPackages = passesCampaigns.map((item) => ({
    name: item.name,
    Pases: item.countTotal,
  }));

  const totalPassesCampaigns = passesCampaigns.reduce(
    (sum, item) => sum + (item.countTotal || 0),
    0
  );

  const passesQuantityZones = passesZones.map((item) => ({
    name: item.name,
    Pases: item.countTotal,
  }));

  const totalPassesZones = passesZones.reduce(
    (sum, item) => sum + (item.countTotal || 0),
    0
  );

  const passesQuantity = passes.map((item) => ({
    name: item.file.name,
    Pases: item.count,
  }));

  const totalPassesQuantity = passes.reduce(
    (sum, item) => sum + (item.count || 0),
    0
  );

  useEffect(() => {
    getActivities();
    getPasses();
    getActivitiesHours();
    getPassesCampaigns();
    getPassesZones();
  }, [ start, end]);

  return (
    <SpaceStyled block={true} direction="vertical">
      {contextHolder}
      <Row justify="center">
        <Col xs={24} md={22}>
          <SpaceStyled block={true} direction="vertical" size="large">
            <Typography.Title level={2} style={{ margin: "0px" }}>
              Gestión de métricas
            </Typography.Title>
            <CardTools title="Panel de control" />
            <Row gutter={[16, 16]}>
              <Col xs={24} md={8}>
                <Card style={{ minHeight: "150px" }}>
                  <Row justify="space-between">
                    <Col>
                      <Statistic
                        title="Defina el período de tiempo"
                        value=" "
                      />
                    </Col>
                    <Col>
                      <AntdTooltip
                        placement="left"
                        title="Seleccione un rango de fechas para filtrar los datos de las gráficas. Solo se mostrarán los datos que estén dentro de las fechas seleccionadas."
                      >
                        <InfoCircleTwoTone />
                      </AntdTooltip>
                    </Col>
                  </Row>
                  <DatePicker.RangePicker
                    onChange={(dates) => {
                      if (Array.isArray(dates)) {
                        const [start, end] = dates;
                        if (start !== null && end !== null) {
                          getActivities();
                          setStart(dayjs(start.format("YYYY-MM-DD")));
                          setEnd(dayjs(end.format("YYYY-MM-DD")));
                          getPasses();
                          getPassesCampaigns();
                          getPassesZones();
                        }
                      }
                    }}
                  />
                </Card>
              </Col>
              {role === "ALLY" && (
                <>
                  <Col xs={24} md={8}>
                    <CardStatistic
                      title="Total de horas activas"
                      value={totalHours}
                      icon={<ClockCircleOutlined />}
                      tooltip="Este valor representa la cantidad total de horas que el rider ha estado activo en el dispositivo."
                      precision={2}
                    />
                  </Col>
                  <Col xs={24} md={8}>
                    <CardStatistic
                      title="Total de pases reproducidos"
                      value={totalPassesQuantity}
                      icon={<SyncOutlined />}
                      tooltip="Este valor representa la cantidad total de pases reproducidos por el rider en el dispositivo."
                      precision={2}
                    />
                  </Col>
                </>
              )}
              {role === "ADVERTISER" && (
                <>
                  <Col xs={24} md={8}>
                    <CardStatistic
                      title="Total de pases reproducidos por campañas"
                      value={totalPassesCampaigns}
                      icon={<ClockCircleOutlined />}
                      tooltip="Este valor representa la cantidad total de pases reproducidos por las campañas."
                      precision={2}
                    />
                  </Col>
                  <Col xs={24} md={8}>
                    <CardStatistic
                      title="Total de pases reproducidos por zonas"
                      value={totalPassesZones}
                      icon={<SyncOutlined />}
                      tooltip="Este valor representa la cantidad total de pases reproducidos por las zonas."
                      precision={2}
                    />
                  </Col>
                </>
              )}
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={24}>
                {role === "ALLY" && (
                  <Card title="Horas que ha estado conectado">
                    <ResponsiveContainer
                      width="100%"
                      height={300}
                      style={{ padding: "5px" }}
                    >
                      <LineChart data={HoursQuantity}>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="Horas"
                          stroke="rgb(233, 117, 54)"
                          strokeWidth={3}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </Card>
                )}
                {role === "ADVERTISER" && (
                  <Card title="Cantidad de pases que se han reproducido por campañas">
                    <ResponsiveContainer
                      width="100%"
                      height={300}
                      style={{ padding: "5px" }}
                    >
                      <LineChart data={passesQuantityPackages}>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="Pases"
                          stroke="rgb(233, 117, 54)"
                          strokeWidth={3}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </Card>
                )}
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginBottom: "25px" }}>
              <Col xs={24} lg={24}>
                {role === "ALLY" && (
                  <Card title="Cantidad de pases que se han reproducido por archivos">
                    <ResponsiveContainer
                      width="100%"
                      height={300}
                      style={{ padding: "5px" }}
                    >
                      <LineChart data={passesQuantity}>
                        <XAxis dataKey="name" />
                        <YAxis />

                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="Pases"
                          stroke="rgb(233, 117, 54)"
                          strokeWidth={3}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </Card>
                )}
                {role === "ADVERTISER" && (
                  <Card title="Cantidad de pases que se han reproducido por zonas">
                    <ResponsiveContainer
                      width="100%"
                      height={300}
                      style={{ padding: "5px" }}
                    >
                      <LineChart data={passesQuantityZones}>
                        <XAxis dataKey="name" />
                        <YAxis />

                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="Pases"
                          stroke="rgb(233, 117, 54)"
                          strokeWidth={3}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </Card>
                )}
              </Col>
            </Row>
          </SpaceStyled>
        </Col>
      </Row>
    </SpaceStyled>
  );
};

export default MetricsPage;
